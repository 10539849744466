import React, { useEffect, useState } from 'react';

import '@arco-design/web-react/dist/css/arco.css';

import './App.css';
import './utils/text.css';
import './utils/font.css';
import { DownloadKindEnum, Download } from './utils/Download';
import { ResolutionValues, useViewport } from './utils/viewport';

import { GalleryPanel } from './gallery/GalleryPanel';
import { TopPanel } from './special/TopPanel';
import { SlidePanel } from './special/SlidePanel';
import { BottomPanel } from './special/BottomPanel';
import { trackingService } from './services/tracking';

function App() {
  const [headOpacity, setHeadOpacity] = useState(0);
  const [show0, setShow0] = useState(false);
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);

  const { resolution } = useViewport();
  const large = resolution >= ResolutionValues.Large;
  useEffect(() => {
    trackingService.view();
  });

  useEffect(() => {
    const onScroll = () => {
      const scrollY = window.scrollY;
      if(scrollY > 76) {
        setHeadOpacity(1);
      } else {
        setHeadOpacity(scrollY / 76);
      }
      const bottomY = scrollY + window.screen.height;
      const pageHeight = large?1080:930;
      if(bottomY > 1*pageHeight + 100) { setShow0(true); }
      if(bottomY > 2*pageHeight + 100) { setShow1(true); }
      if(bottomY > 3*pageHeight + 100) { setShow2(true); }
      if(bottomY > 4*pageHeight + 100) { setShow3(true); }
      if(bottomY > 5*pageHeight + 100) { setShow4(true); }
    }
    // clean up code
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);
  return (
    <>
      <div className={large?"page-header pc-mode":"page-header mobile-mode"} style={{backgroundColor:`rgba(255,255,255,${headOpacity})`}}>
          <div className="logo">
            <img className="logo1" src="/icons/logo1.png" alt=""></img>
            <img className="logo2" src="/icons/logo2.png" alt=""></img>
          </div>
          {large?<Download kind={DownloadKindEnum.Header} name="head"/> : <div></div>}
      </div>
      <div className={large?"page-body pc-mode":"page-body mobile-mode"}>
        <TopPanel/>
        <GalleryPanel index={0} show={show0} />
        <GalleryPanel index={1} show={show1} />
        <GalleryPanel index={2} show={show2} />
        <GalleryPanel index={3} show={show3} />
        <GalleryPanel index={4} show={show4} />
        <SlidePanel/>
        <BottomPanel/>
      </div>
    </>
  );
}

export default App;
