

import '@arco-design/web-react/dist/css/arco.css';
import { Grid } from '@arco-design/web-react';
import { trackingService } from '../services/tracking';

import "./Download.css"

export enum DownloadKindEnum {
    Default = 0,
    Header = 1,
    Andoid = 2,
    iOS = 3,
}

export type ButtonNameType = "head"|"top"|"iOS"|"Android"|"middle"|"bottom"

interface DownloadProps {
    kind?:DownloadKindEnum
    name:ButtonNameType
}

function clickDownload(btnName:ButtonNameType){
    trackingService.download(btnName)
    //if(navigator.userAgent.toLowerCase().indexOf("iphone") > -1){ window.location.href = 'https://apps.apple.com/us/app/pic-ai-photo-enhancer/id6476751797'; }
    window.location.href = 'https://apps.apple.com/us/app/pic-ai-photo-enhancer/id6476751797';

    //if(navigator.userAgent.toLowerCase().indexOf("android") > -1){ window.location.href = 'https://play.google.com/store/apps/details?id=com.exampleapp'; }

    //Update #2
    /*if (!navigator.userAgent.match(/(iPhone|iPod|iPad|Android|BlackBerry|IEMobile)/)) {
         window.location.href = 'https://www.google.com'; //Desktop Browser
    }*/

}

export function Download(props:DownloadProps) {
    if(props.kind === DownloadKindEnum.Default || !props.kind) {
        return <button onClick={(e)=>clickDownload(props.name)}>Download Now</button>
    } else if (props.kind === DownloadKindEnum.Header){
        return <button className="header" onClick={(e)=>clickDownload(props.name)}>Download Now</button>
    } else {
        const isAndroid = props.kind === DownloadKindEnum.Andoid
        return <button className="channel" onClick={(e)=>clickDownload(props.name)}>
            <img src={isAndroid?"/icons/android.svg":"/icons/ios.svg"} alt=""></img>
            <span>{isAndroid?"Android":"iOS"}</span>
        </button>
    }
}