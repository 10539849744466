
import React, { useEffect, useState } from 'react';
import '@arco-design/web-react/dist/css/arco.css';

import { PhotoText } from './PhotoText';
import { PhotoImage } from './PhotoImage';
import { Background } from './Background';

import "./GalleryPanel.css"
import { ResolutionValues, useViewport } from '../utils/viewport';
import { Grid } from '@arco-design/web-react';

interface GalleryPanelProps {
    index:number,
    show:boolean,
}

export function GalleryPanel(props: GalleryPanelProps) {
  const { resolution } = useViewport();
  const large = resolution >= ResolutionValues.Large;

  let imgElement = <PhotoImage index={props.index} />;
  let textElement = <PhotoText index={props.index} />;
  let showClassName = props.show ? "show" : "";

  if(large) {
    let left = props.index % 2 === 0 ? imgElement:textElement;
    let right = props.index % 2 === 1 ? imgElement:textElement;
    return <div className={`panel gallery ${showClassName}`}>
      <Background index={props.index}/>
      <Grid.Col className="wrap wrap-left" span={12}>
        {left}
      </Grid.Col>
      <Grid.Col className="wrap wrap-right" span={12}>
        {right}
      </Grid.Col>
    </div>
  } else {
    return <div className={`panel gallery ${showClassName}`}>
      <div className="wrap">
        {imgElement}
      </div>
      <div className="wrap">
        {textElement}
      </div>
    </div>
  }
}