import thinkingdata from 'thinkingdata-browser';
import type { ButtonNameType } from '../utils/Download';

try {
    thinkingdata.init({
        appId: '1fe471f2ea204439a3cfb3411f40a4ee',
        serverUrl: 'https://te.receive.ifonlyapp.com',
        autoTrack: {
            pageShow: true,
            pageHide: true,
        },
    });
} catch(err) {
    console.log(err);
    console.log('ta error when init');
}

// get parameter like https://www.aipicplus.com?source=xxx
function getParam(p:string) {
    var match = RegExp("[?&]" + p + "=([^&]*)").exec(
        window.location.search
    );
    return match && decodeURIComponent(match[1].replace(/\+/g, " "));
}

export const trackingService = {
    view: function() {
        try {
            const source = getParam("source") || "empty";
            // fbq tracking
            fbq("track", "View", {
                source: source,
            });
            // thinkingdata tracking
            thinkingdata.setSuperProperties({
                channel: 'web',
                user_local_time: new Date(),
            });
            thinkingdata.track("View", {
                source:source
            });
        } catch (err) {
            console.log(err);
            console.log('ta error when track download');
        }
    },
    download: function (buttonName:ButtonNameType) {
        try {
            const source = getParam("source") || "empty";
            // fbq tracking
            fbq("track", "Download", {
                button: buttonName,
                source: source,
            });
            // thinkingdata tracking
            thinkingdata.setSuperProperties({
                channel: 'web',
                user_local_time: new Date(),
            });
            thinkingdata.track("Download", {
                button:buttonName,
                source:source
            });
        } catch (err) {
            console.log(err);
            console.log('ta error when track download');
        }
    },
};
