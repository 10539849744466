



import '@arco-design/web-react/dist/css/arco.css';
import { Divider, Grid } from '@arco-design/web-react';
import { Download } from '../utils/Download';

import "./BottomPanel.css"
import { ResolutionValues, useViewport } from '../utils/viewport';

interface CellProps {
    title: string;
    image: string;
    contents: string[];
}

function Cell(props:CellProps){
    const { resolution } = useViewport();
    const large = resolution >= ResolutionValues.Large;
    if(large) {
        return <Grid.Col className="cell" span={8}>
            <hr/>
            <div className="cell-title">{props.title}</div>
            <img className="image" src={props.image} alt=""></img>
            <div className="cell-contents">
                {props.contents.map((content)=>{
                    return <span>{content}</span>
                })}
            </div>
            <hr/>
        </Grid.Col>
    } else {
        return <div className="cell">
            <div className="cell-title">{props.title}</div>
            <img className="image" src={props.image} alt=""></img>
            <div className="cell-contents">
                {props.contents.map((content)=>{
                    return <span>{content}</span>
                })}
            </div>
        </div>
    }
}

export function BottomPanel() {
    const { resolution } = useViewport();
    const large = resolution >= ResolutionValues.Large;
    const cellList = [
            <Cell
                title="Real-time result"
                image="/icons/cell1.png"
                contents={["Generates in 5 seconds"]}
            />,
            <Cell
                title="Re-roll Option"
                image="/icons/cell2.png"
                contents={["Get endless results to", "meet your satisfaction"]}
            />,
            <Cell
                title="500+ AI Filters"
                image="/icons/cell3.png"
                contents={["One photo, endless", "possibilities"]}
            />
    ]
    return <div className="panel bottom">
        <div className="bottom-header">
            <span>Redefine How Your Pictures</span>
            <span>Should Look Like</span>
        </div>
        {large?<Grid.Row className="bottom-body">
            {cellList}
        </Grid.Row>:<div className="bottom-body">
            <hr/>
            { cellList[0] }
            <hr/>
            { cellList[1] }
            <hr/>
            { cellList[2] }
            <hr/>
        </div>}
        <div className="bottom-footer">
            {!large&&<br/>}
            {!large&&<br/>}
            <Download name="bottom"/>
        </div>
    </div>
}