
import '@arco-design/web-react/dist/css/arco.css';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { Download } from '../utils/Download';

import '@splidejs/react-splide/css';
import '@splidejs/react-splide/css/core';

import "./SlidePanel.css"
import { ResolutionValues, useViewport } from '../utils/viewport';

export function SlidePanel() {
    const { resolution } = useViewport();
    const large = resolution >= ResolutionValues.Large;
    const l:JSX.Element[] = [];
    for(let i=0;i<9;i++) {
        l.push(<SplideSlide className="slide-item">
            <img src={`/images/slide${i}.jpg`} alt="" />
        </SplideSlide>)
    }
    const upTitle = <span className="title color">Photo Retouch Never Looked Easier</span>
    const upContent = <div className="slide-up-tips">
                <span className="tips">Step 1: Upload your best shot</span>
                <span className="tips">Step 2: Upload your failed shot</span>
                <span className="tips">Get your failed shot fixed</span>
                <div style={large?{marginTop:"54px", marginLeft:"86px"}:{alignSelf:"center", marginTop:"30px", marginBottom:"50px"}}>
                    <Download name="middle"/>
                </div>
            </div>
    const upImage = <img className="slide-up-img" src="/images/middle.png" alt="">
            </img>
    const downText = <div className="slide-down-text" style={{marginBottom:large?"34px":"34px", width:large?undefined:"80%", textAlign:large?undefined:"center"}}>
                <span className="title color">Explore New Identities With AI Portriats</span>
                <span className="content color">Unlock the potential of your portraits and let your creativity soar with Pic+.</span>
            </div>
    const downSplide = <Splide options={{
                gap: "4vw",
                pagination:false,
                type:"loop",
                rewind:true,
                arrows:false,
                drag:"free",
                snap:false,
                perPage:large?4:2
            }}>
                {l}
            </Splide>
    if(large) {
        return <div className="panel slide-panel">
            <div className="slide-up">
                <div style={{width:large?"655px":undefined}}>
                    {upTitle}
                    {upContent}
                </div>
                {upImage}
            </div>
            <div className="slide-down">
                {downText}
                {downSplide}
            </div>
        </div>
    } else {
        return <div className="panel slide-panel">
            {upTitle}
            <br/>
            {upImage}
            {upContent}
            {downText}
            {downSplide}
        </div>
    }
}