
import React, { useEffect, useState } from 'react';
import '@arco-design/web-react/dist/css/arco.css';
import { Grid } from '@arco-design/web-react';

import "./PhotoImage.css"
import { ResolutionValues, useViewport } from '../utils/viewport';

const imgSrcList = [
    "/images/photo0.jpg",
    "/images/photo1.jpg",
    "/images/photo2.jpg",
    "/images/photo3.jpg",
    "/images/photo4.jpg",
]

interface PhotoImageProps{
    index:number,
}

export function PhotoImage(props:PhotoImageProps) {
    return <img className="gallery-image" src={imgSrcList[props.index]} alt=""/>
}