


import React, { CSSProperties, useEffect, useState } from 'react';
import '@arco-design/web-react/dist/css/arco.css';
import { Grid } from '@arco-design/web-react';

import "./Background.css"

interface BackgroundProps {
    index: number;
}

const ballPosList:CSSProperties[] = [
    {
        width: "333px",
        height: "333px",
        top:"-167px",
        right:"-109px",
        background: "#F8C9E8",
        opacity: 0.2,
    },
    {
        width: "452px",
        height: "452px",
        top:"175px",
        right:"-180px",
        background: "#A868FD",
        opacity: 0.1,
    },
    {
        width: "500px",
        height: "500px",
        bottom:"50px",
        left:"243px",
        background: "#DDCAF6",
        filter:"blur(100px)",
        opacity: 0.8,
    },
    {
        width: "550px",
        height: "550px",
        bottom:"40px",
        right:"233px",
        background: "#F4CCEC",
        filter:"blur(100px)",
        opacity: 0.5,
    },
    {
        width: "500px",
        height: "500px",
        bottom:"40px",
        left:"243px",
        background: "#DDCAF6",
        filter:"blur(100px)",
        opacity: 0.8,
    },
]

export function Background(props: BackgroundProps) {
    const textElement = <span className="bg-text">Pic+</span>
    const ballElement = <div className="bg-ball" style={ballPosList[props.index]}></div>
    return <div className="gallery-background">
        {ballElement}
        <Grid.Col className="bg-wrap" span={12}>
            {props.index % 2 === 1 && textElement}
        </Grid.Col>
        <Grid.Col className="bg-wrap" span={12}>
            {props.index > 0 && props.index % 2 === 0 && textElement}
        </Grid.Col>
    </div>
}