

import '@arco-design/web-react/dist/css/arco.css';

import "./TopPanel.css"
import { ResolutionValues, useViewport } from '../utils/viewport';
import { DownloadKindEnum, Download } from '../utils/Download';

export function TopPanel() {
    const { resolution } = useViewport();
    const large = resolution >= ResolutionValues.Large;
    return <div className="panel top">
        <div className="top-left">
            <span className="title black">
                Pic+ AI Photo Enhancer
            </span>
            <span className="content black">
                Fix, refine and enhance your pictures in one take with the power of Pic+ Al image enhancer.
            </span>
            {large ? <div style={{display:"flex"}}>
                <Download kind={DownloadKindEnum.iOS} name="iOS"/>
                <div style={{margin:"5px"}}></div>
                <Download kind={DownloadKindEnum.Andoid} name="Android"/>
            </div> : <Download name="top"/>}
        </div>
        <img className="top-right" src="/images/top.jpg" alt="">
        </img>
    </div>
}