
import React, { useEffect, useState } from 'react';
import '@arco-design/web-react/dist/css/arco.css';
import { Grid } from '@arco-design/web-react';

import "./PhotoText.css"
import { ResolutionValues, useViewport } from '../utils/viewport';

interface TextInfo {
    title:string,
    content:string,
    nextContent?:string,
}

const textInfoList:TextInfo[] = [
  {
    title:"Had that one good shot?  Duplicate them!",
    content:"We've all got that one good shot we use everywhere. Instead of putting effort on remaking that good moment，spend more time on enjoying the moment. Replace every picture with your best take with Pic+ AI Photo Enhancement.",
  },
  {
    title:"Make Every Picture Insta-Worthy",
    content:"Elevate your photos to social media stardom with our AI photo enhancer.",
    nextContent:"Transform ordinary snapshots into eye-catching masterpieces with just a few taps. From enhancing colors to refining details, our app ensures that every picture you share is sure to garner likes and admiration.",
  },
  {
    title:"Fix your facial expression",
    content:"Say goodbye to awkward smiles and closed eyes!",
    nextContent:"Our AI-powered facial enhancement feature detects and corrects imperfections, ensuring that everyone in your photos looks their best. Whether it's a subtle adjustment or a complete smile makeover, you can trust our app to capture your most flattering moments effortlessly.",
  },
  {
    title:"No More Blurryness",
    content:"Blur be gone! Our cutting-edge technology sharpens even the most blurred images, so you can relive your memories with crystal-clear clarity.",
    nextContent:"Whether it's a shaky hand or a moving subject, our AI photo enhancer works its magic to bring your photos into focus, preserving every detail for posterity.",
  },
  {
    title:"Stand Out in Group Photo",
    content:"Never get lost in the crowd again!",
    nextContent:"Our app's group photo enhancement feature ensures that you shine bright amidst a sea of faces. With intelligent editing tools that highlight your presence while maintaining the natural look of the scene, you'll always be the center of attention in every group shot.",
  },
]

interface PhotoTextProps {
    index:number,
}

export function PhotoText(props:PhotoTextProps) {
    const { resolution } = useViewport();
    const large = resolution >= ResolutionValues.Large;
    const textInfo = textInfoList[props.index];
    const color = props.index===0?"black":"color";
    return <div className={`photo-text ${!large?"middle-text":(props.index%2===0?"right-text":"left-text")}`}>
        <span className={"title "+color}>{textInfo.title}</span>
        <span className={"content "+color}>{textInfo.content}</span>
        <span className={"content "+color}>{textInfo.nextContent}</span>
    </div>
}